.Dropdown-disabled {
  background-color: #BBBBBB;
}

.Dropdown-arrow-wrapper{
  margin-left: auto;
}

.is-open .Dropdown-arrow{
  border-width: 0 7px 7px;
}

.Dropdown-arrow{
  border-width: 7px 7px 0;
}

.dropdown-text{
  min-height: 25px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.dropdown-menu-header{
  background-color: #EFEFEF;
  border:none;
  border-radius:15px;
}

.dropdown-header{
  font-size: 13px;
  font-family: poppinslight;
}

.dropdown-control-header{
  display: flex;
  background-color: #EFEFEF;
  align-items: center;
  padding: 8px 8px 8px 10px;
  border: none;
}

@media only screen and (max-width: 768px) {
  .dropdown-control-header{
    border-radius: 15px;
  }
}

.dropdown-arrow-header{
  position: relative;
  right: 0;
  top: 0;
  margin-left: auto;
}

